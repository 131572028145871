// Importing styles
import '../article/article.scss';
import './results.scss';
import '../../assets/styles/_buttons.scss';

//Importing modules

import ResultDetails from './ResultDetails';
import ResultDescription from './ResultDescription';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import { TagsTypes } from '../../data/main.ts';

const Result = (props) => {
  const itemId = props?.data?.objectID;
  const name = props?.data?.name;
  const descriptions = props?.data?.description;
  const contact = {
   phone: props?.data?.phone,
   email:  props?.data?.email,
   website: props?.data?.website
  }
  const tags = {
    isPaid: props?.data?.isPaid,
    isFree: props?.data?.isFree,
    isRemote: props?.data?.isRemoteService,
    isLocal: props?.data?.isLocalService,
    isCommuting: props?.data?.isCommutingService,
  };
  console.log("contact", contact);

  return (
    <div className="result-wrapper">
      <article className="article result">
        <header className="result__header">
          {tags && Object.values(tags).some((v) => v) && (
            <div className="section-tags">
              {tags.isPaid && <span className="tag">{TagsTypes.PAID}</span>}
              {tags.isFree && <span className="tag">{TagsTypes.FREE}</span>}
              {tags.isLocal && <span className="tag">{TagsTypes.LOCAL}</span>}
              {tags.isRemote && <span className="tag">{TagsTypes.REMOTE}</span>}
              {tags.isCommuting && (
                <span className="tag">{TagsTypes.COMMUTING}</span>
              )}
            </div>
          )}
          <h2 className="result-title">{name}</h2>
        </header>
        <ResultDescription
          descriptions={descriptions}
          tags={tags}
          contact={contact}
          itemId={itemId}
          single={false}
        />
      </article>
    </div>
  );
};

Result.propTypes = {
  name: PropTypes.object,
  descriptions: PropTypes.array,
  details: PropTypes.object,
  contact: PropTypes.object,
  tags: PropTypes.object,
};

export default Result;
