// Importing styles

// Importing modules
import { Container, Row} from 'react-bootstrap';
import { DetailsTitles } from '../../data/main.ts';
import PropTypes from 'prop-types';

const ResultDetails = (props) => {
  const ranges = props?.details?.ranges;
  const conditions = props?.details?.conditions;
  const groups = props?.details?.groups;
  const deliveryAddress = props?.details?.deliveryAddress;
  const realizationAddress = props?.details?.realizationAddress;
  const openingInfo = props?.details?.openingInfo ?? '';
  const remoteAddres = props?.details?.remoteAddres ?? '';

  const addressComponent = (addressObject) => {
    let name = addressObject?.title;
    let city = addressObject?.town ?? '';
    let postcode = addressObject?.postalCode ?? '';
    let community = addressObject?.postalTown ?? '';
    let street = addressObject?.street ?? '';
    let streetNumber = addressObject?.buildingNumber ?? '';
    return (
      <div>
        {name !== undefined && <p>{name}</p>}
        {(street !== '' || streetNumber !== '' || city !== '') && (
          <p> {street + ' ' + streetNumber + ' ' + city}</p>
        )}
        {(postcode !== '' || community !== '') && (
          <p> {postcode + ' ' + community}</p>
        )}
      </div>
    );
  };
  return (
    <Container fluid="lg">
      <Row>
        <div className="col col-12 details-wrapper">
          <h4 className="section-title">{DetailsTitles.RangesTitle}</h4>
          {ranges && ranges.length > 0 ? (
            <ul>
              {ranges.map((range, index) => (
                <li key={index}>{range}</li>
              ))}
            </ul>
          ) : (
            <p>Brak</p>
          )}
        </div>
        <div className="col col-12 details-wrapper">
          <h4 className="section-title">
            {DetailsTitles.AdditionalConditionsTitle}
          </h4>
          {conditions && conditions.length > 0 ? (
            <ul>
              {conditions.map((condition, index) => (
                <li key={index}>{condition}</li>
              ))}
            </ul>
          ) : (
            <p>Brak</p>
          )}
        </div>
        <div className="col col-12 details-wrapper">
          <h4 className="section-title">{DetailsTitles.GroupTitle}</h4>
          {groups && groups.length > 0 ? (
            <ul>
              {groups.map((group, index) => (
                <li key={index}>{group}</li>
              ))}
            </ul>
          ) : (
            <p>Brak</p>
          )}
        </div>
        {openingInfo && openingInfo !== '' && (
          <div className="col col-12 details-wrapper">
            <h4 className="section-title">{DetailsTitles.OpeningHoursTitle}</h4>
            {openingInfo}
          </div>
        )}
        {remoteAddres && remoteAddres !== '' && (
          <div className="col col-12 details-wrapper">
            <h4 className="section-title">
              {DetailsTitles.RemoteAddressTitle}
            </h4>
            <a href={remoteAddres}>{remoteAddres}</a>
          </div>
        )}
        {deliveryAddress && Object.values(deliveryAddress).some((v) => v) && (
          <div className="col col-12 details-wrapper">
            <h4 className="section-title">
              {DetailsTitles.DeliveryAddressTitle}
            </h4>
            {addressComponent(deliveryAddress)}
          </div>
        )}
        {realizationAddress &&
          Object.values(realizationAddress).some((v) => v) && (
            <div className="col col-12 details-wrapper">
              <h4 className="section-title">{DetailsTitles.RealizationAddressTitle}</h4>
              {addressComponent(realizationAddress)}
            </div>
          )}
      </Row>
    </Container>
  );
};
ResultDetails.propTypes = {
  conditions: PropTypes.array,
  ranges: PropTypes.array,
  groups: PropTypes.array,
  deliveryAddress: PropTypes.object,
  realizationAddress: PropTypes.object,
};

export default ResultDetails;
