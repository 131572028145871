// Importing styles
import "./footer.scss";

// Importing assets
import logo from "../../assets/images/footer-logos.png";
import info from "../../assets/images/info.png";
import komitet from "../../assets/images/komitet.png";
import niw from "../../assets/images/niw.png";
import nowefio from "../../assets/images/nowefio.png";
import rops from "../../assets/images/rops.png";
import podlaskie from "../../assets/images/podlaskie.png";
//import wojewodztwo from '../../assets/images/wojewodztwo.jpg';

// Importing modules
import { Container, Row, Col } from "react-bootstrap";
import { SharedText } from "../../data/main.ts";
import Brand from "../brand/Brand";

const Footer = () => {
  return (
    <footer className="footer">
      <Container fluid="lg">
        <Row>
          <Col md={3} xs={12}>
            <div className="footer__info">
              <Brand />
            </div>
          </Col>
          <Col md={9} xs={12}>
            <div className="footer__logo-wrapper">
              <Row>
                <Col md={4} xs={12}>
                  <img
                    src={rops}
                    className="footer__logo rops"
                    alt={SharedText.FooterLogoRops}
                  ></img>
                </Col>
                <Col md={4} xs={12}>
                  <img
                    src={info}
                    className="footer__logo"
                    alt={SharedText.FooterLogoInfoAlt}
                  ></img>
                </Col>
                <Col md={4} xs={12}>
                  <img
                    src={niw}
                    className="footer__logo"
                    alt={SharedText.FooterLogoNIWAlt}
                  ></img>
                </Col>
             
                <Col md={4} xs={12}>
                  <img
                    src={nowefio}
                    className="footer__logo"
                    alt={SharedText.FooterLogoNowefioAlt}
                  ></img>
                </Col>
                <Col md={4} xs={12}>
                  <img
                    src={komitet}
                    className="footer__logo"
                    alt={SharedText.FooterLogoKomitetAlt}
                  ></img>
                </Col>
                <Col md={4} xs={12}>
                  <img
                    src={podlaskie}
                    className="footer__logo"
                    alt={SharedText.FooterLogoWojewodztwo}
                  ></img>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
