// Importing styles
import "./multiSelect.scss";

// Importing modules
import PropTypes from "prop-types";
import React, { useState, useRef, useEffect } from "react";
import Select, { components, SelectInstance } from "react-select";

const MultiSelectDropdown = (props) => {
  const isDisabled = props.isDisabled;
  const options = props.options;
  const handleMultiSelectValueChange = props.handleMultiSelectValueChange;
  const name = props.name;

  const [selected, setSelected] = useState(props.selected || []);

  const InputOption = ({
    getStyles,
    Icon,
    isSelected,
    children,
    innerProps,
    ...rest
  }) => {
    // styles
    let bg = "transparent";

    const style = {
      alignItems: "center",
      backgroundColor: bg,
      color: "inherit",
      display: "flex",
    };
    const props = {
      ...innerProps,
      style,
    };

    const [, setChecked] = useState(false);

    return (
      <components.Option
        {...rest}
        isSelected={isSelected}
        getStyles={getStyles}
        innerProps={props}
        className="multiselect__option"
        tabindex="-1"
      >
        <div className="multiselect__option-value">{children}</div>
        <input
          type="checkbox"
          className="multiselect__option_checkbox form-check-input"
          checked={isSelected}
          onChange={() => {
            setChecked(isSelected);
          }}
        />
      </components.Option>
    );
  };

  const MultiValueContainer = ({ selectProps, data }) => {
    const label = data.label;
    const allSelected = selectProps.value;
    const index = allSelected.findIndex((selected) => selected.label === label);
    const isLastSelected = index === allSelected.length - 1;
    const labelSuffix = isLastSelected ? `Wybrano: ${allSelected.length}` : "";
    const val = allSelected.length > 1 ? `${labelSuffix}` : label;
    return val;
  };

  const CUSTOM_COMPONENTS = {
    Option: InputOption,
    MultiValueContainer: MultiValueContainer,
  };

  const selectRef = useRef();

  useEffect(() => {
    if (selectRef.current) {
      const selectContainer = selectRef.current.controlRef;
      if (isDisabled) {
        selectContainer.setAttribute('aria-disabled', 'true');
      } else {
        selectContainer.removeAttribute('aria-disabled');
      }
    }
  }, [isDisabled]);

  return (
    <div>
      <Select
        ref={selectRef}
        className="multiselect"
        classNamePrefix="multiselect__inner"
        defaultValue={selected}
        options={options}
        isMulti
        isSearchable={false}
        isDisabled={isDisabled}
        hideSelectedOptions={false}
        placeholder={'Wybierz...'}
        closeMenuOnSelect={false}
        inputId={'search-widget--' + name}
        value={options.filter((obj) => selected.includes(obj.value))}
        onChange={(options, e) => {
          console.log(options);
          if (Array.isArray(options)) {
            const arrayValues = options.map((opt) => opt.value);
            console.log(arrayValues);
            setSelected(arrayValues);
            handleMultiSelectValueChange(arrayValues, name);
          }
        }}
        openMenuOnFocus={false}
        tabSelectsValue={false}
        onKeyDown={(e) => {
          console.log(e);
        }}
        components={CUSTOM_COMPONENTS}
      />
    </div>
  );
};
MultiSelectDropdown.propTypes = {
  options: PropTypes.array,
  handleMultiSelectValueChange: PropTypes.func,
  name: PropTypes.string,
  isDisabled: PropTypes.bool,
};

export default MultiSelectDropdown;
