// Importing styles

// Importing modules
import PropTypes from 'prop-types';
import {
  Envelope,
  Globe2,
  Telephone,
  ArrowRightSquareFill,
} from 'react-bootstrap-icons';
import { ButtonText, ContactTitles } from '../../data/main.ts';

const ResultContact = (props) => {
  const id = props?.itemId;
  const single = props?.single;
  const phoneNumber = props?.contact?.phone;
  const email = props?.contact?.email;
  const www = props?.contact?.website;

  return (
    <div role="contentinfo" className="result__contact">
      <h3 className="section-title">{ContactTitles.ContactTitle}</h3>
      <div className="section-table">
        {phoneNumber && phoneNumber.length > 0 && (
          <div className="table-row">
            <div className="table-col__wrapper">
              <div className="table-col icon">
                <Telephone />
              </div>
              <div className="table-col name">{ContactTitles.PhoneTitle}:</div>
            </div>
            <div className="table-col__wrapper">
              {phoneNumber.map((item, index) => (
                <div className="table-col value" key={index}>
                  <a href={`tel:${item}`}>{item}</a>
                </div>
              ))}
            </div>
          </div>
        )}
        {email && email.length > 0 && (
          <div className="table-row">
            <div className="table-col__wrapper">
              <div className="table-col icon">
                <Envelope />
              </div>
              <div className="table-col name">{ContactTitles.EmailTitle}:</div>
            </div>
            <div className="table-col__wrapper">
              {email.map((item, index) => (
                <div className="table-col value" key={index}>
                  <a href={`mailto:${item}`}>{item}</a>
                </div>
              ))}
            </div>
          </div>
        )}
        {www && (
          <div className="table-row">
            <div className="table-col__wrapper">
              <div className="table-col icon">
                <Globe2 />
              </div>
              <div className="table-col name">
                {ContactTitles.WebsiteTitle}:
              </div>
            </div>
            <div className="table-col__wrapper">
              <div className="table-col value">
                <a href={www} target="_blank" rel="noreferrer">
                  {www}
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
      {id && !single && (
        <div className="section-table details">
          <div className="table-row">
            <div className="table-col value">
              <a target="_blank" href={'/Details/' + id} rel="noreferrer">
                {ButtonText.ClickHereButton} <ArrowRightSquareFill />
                <span class="screen-readers-only">otwiera nowe okno</span>
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
ResultContact.propTypes = {
  phoneNumber: PropTypes.array,
  www: PropTypes.string,
  email: PropTypes.array,
};

export default ResultContact;
